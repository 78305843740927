<script>
import api from '@/services/api';
import PageTitle from '@/components/PageTitle';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import { BCol, BPagination, BRow, BTable } from 'bootstrap-vue';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import { checkLang, generateIconColorToBoolean, generateIconToBoolean } from '@/util/helper';

export default {
    name: 'TelegramChannelShow',
    components: {
        ProgressLinear,
        PageTitle,
        BTable,
        BCol,
        BRow,
        BPagination
    },
    mixins: [CRUDPageMixin],
    data() {
        return {
            items: [],
            // regionOptions: [],
            dataForModal: {
                telegram_id: null,
                current_total: null,
                last_added: null,
                current_telegram: null
            },
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 20
            },
            localLang: localStorage.getItem('lang'),
            requestPending: false
        };
    },

    watch: {
        '$route.query': {
            handler(query) {
                this.pagination.current = query.page || 1;
                this.getChannelStatistics();
            },
            deep: true,
            immediate: true
        },

        'pagination.current': {
            handler(page) {
                let newPage = page;
                if (this.pagination.current > this.pagination.totalItem) {
                    newPage = 1;
                }
                this.replaceRouter({
                    ...this.query,
                    page: newPage
                });
            }
        }
    },

    computed: {
        fields() {
            return [
                {
                    key: 'telegram_id',
                    label: 'Telegram Id'
                },
                {
                    key: 'current_telegram',
                    label: this.$t('titles.current_telegram')
                    // label: 'Текущие подписчики телеграм канала'
                },
                {
                    key: 'current_total',
                    label: this.$t('titles.current_total')
                    // label: 'Подписчики от нас (ОБЩИЙ)'
                },
                {
                    key: 'last_added',
                    label: this.$t('titles.last_added')
                    // label: 'Подписчики от нас (ЧАС)'
                },
                {
                    key: 'created_at',
                    label: this.$t('titles.created_at')
                }
                // {
                //     key: 'published_at',
                //     label: this.$t('titles.published_at'),
                //     formatter: (published_at) =>
                //         formatDateToYMD(published_at) + ' ' + formatDateToHMS(published_at)
                // }
            ];
        },

        query() {
            return Object.assign({}, this.$route.query);
        },

        hasItems() {
            return this.items && this.items.length > 0;
        },

        showPagination() {
            return this.hasItems && !this.requestPending;
        }
    },

    async mounted() {
        this.requestPending = true;
        await this.getChannelStatistics();
        this.requestPending = false;
    },

    methods: {
        checkLang,

        async getChannelStatistics(item) {
            this.updatingItem = item;
            const id = this.$route.params.id;
            const body = {
                id: id,
                page: this.pagination.current,
                limit: this.pagination.perPage
            };
            await api.channels
                .fetchOneChannelStatistics(body)
                .then((response) => {
                    this.pagination = response.data.pagination;
                    if (this.pagination.current > this.pagination.totalItem) {
                        this.pagination.current = 1;
                    }
                    this.items = response.data.result;
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        changePerPage(perPage) {
            this.newsParams = {
                perPage,
                page: 1
            };
            this.getChannelStatistics();
        },

        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        },

        generateIconToBoolean,
        generateIconColorToBoolean
    }
};
</script>

<template>
    <div>
        <!--    v-if="hasAccess('coin_award','view')"-->
        <page-title>
            <template #title>
                {{ $t('statistics') }}
                <span v-if="items[0] && items[0].channel"> {{ items[0].channel.name }}</span>
            </template>
            <!--            <template v-if="hasAccess('cities', 'create')" #button>-->
            <!--                <b-button v-b-modal.createUpdateModal class="ml-auto" variant="success">-->
            <!--                    {{ $t('button.add') }}-->
            <!--                    <feather-icon icon="PlusIcon" />-->
            <!--                </b-button>-->
            <!--            </template>-->
        </page-title>

        <!--  TABLE  -->
        <div class="card">
            <progress-linear v-if="requestPending" />
            <b-table
                v-else
                :fields="fields"
                :items="items"
                :responsive="true"
                borderless
                class="news__table"
                outlined
                striped
            >
                <template #cell(id)="{ item }">
                    {{ item.id }}
                </template>
            </b-table>
        </div>

        <!--  PAGINATION  -->
        <b-row>
            <b-col class="offset-3" cols="9">
                <b-pagination
                    v-if="showPagination"
                    v-model="pagination.current"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalItem"
                    class="float-right mr-4 mb-0"
                    first-number
                    last-number
                />
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

::v-deep .vs__dropdown-menu {
    min-height: auto;
    //height: 100px;
}

.validation__red {
    color: red;
}

.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100px;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100px;
            height: 100%;
            object-fit: cover;
        }
    }
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100%;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}
</style>
